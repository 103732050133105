.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100000000;
  height: 100vh;
  padding: 3rem 1rem;
  background: rgba(17, 17, 17, 0.25);
  overflow-y: hidden;
  cursor: pointer;
  backdrop-filter: blur(10px);
}

.container {
  width: 100%;
  max-width: 768px;
  height: fit-content;
  border-radius: 1.2rem;
  overflow: hidden;
  background: rgb(255, 254, 254);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  cursor: auto;
  padding: 2rem;
}

.modalNotice {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding: 0;
  background: transparent;
  overflow-y: hidden;
  cursor: pointer;
}

.noticeCard {
  width: 50%;
  max-width: 300px;
  height: fit-content;
  border-radius: 1.2rem 0 0 1.2rem;
  overflow: hidden;
  background: rgb(255, 254, 254);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  cursor: auto;
  padding: 0.5rem 1rem 1.5rem 1rem;
  display: flex;
  flex-direction: column;
}
